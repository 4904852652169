@tailwind base;
@tailwind components;
@tailwind utilities;
 
.audioTile{

  height: 420px;
  width: 480px;
  background-color: rgb(50, 50, 50);
  padding: .25em 0;

  .audio{

    max-height: 360px;
  }

  .bg-gray{

    background-color: gray;
  }

  .tileGrid{

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 290px 180px;
    margin: 10px;
    align-items: center;

    .tileLabels{
      display: grid;
      grid-auto-flow: row;

      :last-child{

        font-size: small;
        color: gray;
      }
    }

    .tileButtons{

      button{
        display: flex;
        padding: .5em;
        width: 90px;
        border-radius: 1em;
      }

      .rec{

        background-color: rgb(64, 106, 255);
      }

      .stop{

        background-color: rgb(160, 0, 0);
      }
    }
  }
}
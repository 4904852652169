@tailwind base;
@tailwind components;
@tailwind utilities;

$backgroundcolor: rgb(34, 34, 34);
$problue: rgb(64, 106, 255);

body {

  background-color: $backgroundcolor;
  color: white;
}

.center {

  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.welcome {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  h1{

    font-size: 1.5em;
  }

  p{

    text-align: center;
    font-size: .75em;
  }

  .addsrc {

    padding: 0.75em 1.5em;
    background-color: rgb(64, 106, 255);
    border-radius: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5em;

    img{
      margin-bottom: 2px;
    }

    label{
      cursor: pointer;
    }
  }
}
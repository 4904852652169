@tailwind base;
@tailwind components;
@tailwind utilities;

.contextMenu{

  background-color: rgb(21, 21, 21);
  border-radius: .5em;
  position: absolute;
  top: 9em;
  left: 20em;
  z-index: 99;

  .contextEntry{

    padding: 0.5em 2.5em 0.5em 0.5em;
    border-radius: .5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
  }

  .contextEntry:hover{

    background-color: black;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #F5F5F5;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-image: -webkit-gradient(linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122, 153, 217)),
        color-stop(0.72, rgb(73, 125, 189)),
        color-stop(0.86, rgb(28, 58, 148)));
  }
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  font-family: sans-serif;
}